import paths, { rootPaths } from './paths';
import { mockRotations } from 'data/mock';

console.log('Mock Rotations:', mockRotations);
// Find the current rotation ID
const currentRotationId = Object.values(mockRotations).find(rotation => rotation.isCurrent)?.id || 1;
console.log('Current Rotation ID:', currentRotationId);

const homeRoute = {
  label: 'Home',
  labelDisable: true,
  children: [
    {
      name: 'Home',
      icon: 'home',
      to: rootPaths.root,
      active: true,
      exact: true
    }
  ]
};

export const adminAreaRoutes = {
  label: 'Admin Area',
  children: [
    {
      name: 'Current Rotation',
      active: true,
      icon: 'calendar-alt',
      to: paths.viewRotation(`view/${currentRotationId}`),
      exact: true
    },
    {
      name: 'Create Rotation',
      active: true,
      icon: 'plus',
      to: paths.newRotation,
      exact: true
    },
    {
      name: 'Personnel',
      icon: 'users',
      to: '/admin/personnel',
      active: true
    },
    {
      name: 'Review Requests',
      active: true,
      icon: 'tasks',
      to: paths.reviewRequests,
      exact: true
    },
    {
      name: 'Rotations List',
      active: true,
      icon: 'list',
      to: paths.rotationsList,
      exact: true
    }
  ]
};

export const userAreaRoutes = {
  label: 'User Area',
  children: [
    {
      name: 'My Calendar',
      icon: 'calendar-alt',
      to: paths.userCalendar,
      active: true
    },
    {
      name: 'Assignment Requests',
      icon: 'tasks',
      to: paths.userRequests,
      active: true
    }
  ]
};

export default [homeRoute, adminAreaRoutes, userAreaRoutes];
