/**
 * Services
 * 
 * This file exports services based on the configuration flag.
 * If useMockData is true, it exports mock services.
 * Otherwise, it exports real API services.
 */

import { useMockData } from 'config/api.config';
import * as mockServices from './mock';
import * as apiServices from './api';

// Export services based on configuration
export const personnelService = useMockData ? mockServices.personnelService : apiServices.personnelService;
export const rotationsService = useMockData ? mockServices.rotationsService : apiServices.rotationsService;
export const userRequestsService = useMockData ? mockServices.userRequestsService : apiServices.userRequestsService;
export const reviewRequestsService = useMockData ? mockServices.reviewRequestsService : apiServices.reviewRequestsService;
export const userCalendarService = useMockData ? mockServices.userCalendarService : apiServices.userCalendarService;
export const wizardService = useMockData ? mockServices.wizardService : apiServices.wizardService;

// Default export for backward compatibility
export default {
  personnelService,
  rotationsService,
  userRequestsService,
  reviewRequestsService,
  userCalendarService,
  wizardService
}; 