import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'assets/img/logo.png';
import logoSmall from 'assets/img/logo-small.png';

const Logo = ({ at = 'auth', className, textClass, ...rest }) => {
  const isCollapsed = rest.width === 40;
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' },
        { 'footer-brand': at === 'footer' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex',
          {
            'align-items-center': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center fw-bolder fs-4 mb-4': at === 'auth',
            'align-items-center justify-content-center': at === 'footer'
          },
          className
        )}
      >
        <div 
          style={{ 
            width: isCollapsed ? 40 : 180,
            height: isCollapsed ? 40 : 46,
            overflow: 'hidden',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: isCollapsed ? 'center' : 'flex-start',
            padding: isCollapsed ? 0 : '0.5rem 0'
          }}
        >
          <img
            className={isCollapsed ? '' : 'me-2'}
            src={isCollapsed ? logoSmall : logo}
            alt="Logo"
            style={{ 
              height: isCollapsed ? '20px' : '32px',
              width: 'auto',
              maxWidth: '100%',
              objectFit: 'contain',
              transition: 'all 0.2s ease'
            }}
          />
        </div>
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth', 'footer']),
  className: PropTypes.string,
  textClass: PropTypes.string
};

export default Logo;
