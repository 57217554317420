import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import useAdvanceTable from 'hooks/useAdvanceTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PersonnelProfileModal from './PersonnelProfileModal';
import { personnelService } from 'services';

const Personnel = () => {
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [personnel, setPersonnel] = useState([]);
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Fetch personnel and skills data
        const personnelData = await personnelService.getAll();
        const skillsData = await personnelService.getSkills();
        
        setPersonnel(personnelData);
        setSkills(skillsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching personnel data:', err);
        setError('Failed to load personnel data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleNameClick = (person) => {
    setSelectedPerson(person);
    setShowProfileModal(true);
  };

  const columns = [
    {
      accessorKey: 'name',
      header: 'Name',
      meta: {
        headerProps: { style: { minWidth: '200px' }, className: 'ps-3 text-900' },
        cellProps: { className: 'ps-3 fw-semi-bold' }
      },
      cell: ({ row: { original } }) => (
        <div className="d-flex align-items-center">
          <span 
            onClick={() => handleNameClick(original)}
            role="button" 
            className="text-primary cursor-pointer"
          >
            {original.name}
          </span>
          <span className="ms-2 text-500">({original.type})</span>
        </div>
      )
    },
    ...skills.map(skill => ({
      accessorKey: skill,
      header: skill,
      meta: {
        headerProps: { className: 'text-center text-900' },
        cellProps: { className: 'text-center' }
      },
      cell: ({ row: { original } }) => (
        original.skills.includes(skill) ? (
          <FontAwesomeIcon 
            icon="check" 
            className="text-success"
          />
        ) : null
      )
    }))
  ];

  const table = useAdvanceTable({
    data: personnel,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10
  });

  if (loading) {
    return (
      <Card>
        <Card.Body className="text-center p-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-3 text-700">Loading personnel data...</p>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <Card.Body className="text-center p-5">
          <div className="text-danger mb-3">
            <FontAwesomeIcon icon="exclamation-triangle" size="2x" />
          </div>
          <p className="text-700">{error}</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs="auto">
              <h5 className="mb-0">Personnel Skills Matrix</h5>
            </Col>
            <Col xs="auto">
              <AdvanceTableSearchBox table={table} />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTableProvider {...table}>
            <AdvanceTable
              headerClassName="bg-200 text-900 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                bordered: true,
                hover: true,
                size: 'sm',
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
            <div className="mt-3">
              <AdvanceTableFooter
                rowCount={true}
                rowInfo={true}
                navButtons={true}
                rowsPerPageSelection={true}
              />
            </div>
          </AdvanceTableProvider>
        </Card.Body>
      </Card>

      <PersonnelProfileModal 
        show={showProfileModal}
        onHide={() => setShowProfileModal(false)}
        person={selectedPerson}
      />
    </>
  );
};

export default Personnel;