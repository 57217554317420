import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import paths from 'routes/paths';
import { rotationsService } from 'services';

const getIconColor = (to) => {
  // Admin area icons - using more subtle, professional colors
  if (to.includes('view/')) return 'primary';    // Main view - standard blue
  if (to.includes('/dashboard/new-rotation')) return 'success';    // Creation action - positive green
  if (to.includes('/admin/personnel')) return 'info';             // Information view - soft blue
  if (to.includes('/admin/review-requests')) return 'primary';    // Management action - standard blue
  if (to.includes('/admin/rotations-list')) return 'info';        // List view - soft blue
  
  // User area icons - using warm colors for personal items
  if (to.includes('/user/')) return 'warning';                    // Warm orange for personal items
  
  return 'primary'; // default color
};

const NavTile = ({ to, icon, title, description }) => (
  <Col sm={6} lg={3} className="mb-4">
    <Card as={Link} to={to} className="text-decoration-none h-100 hover-actions-trigger">
      <Card.Body className="text-center">
        <div className="mb-3 d-flex align-items-center justify-content-center" style={{ height: '60px' }}>
          <FontAwesomeIcon 
            icon={Array.isArray(icon) ? icon : ['fas', icon]} 
            className={`fs-1 text-${getIconColor(to)}`}
            style={{ filter: 'drop-shadow(0 0 6px rgba(0,0,0,0.1))' }}
          />
        </div>
        <h5 className="fw-medium text-700">{title}</h5>
        <p className="fs--1 mb-0 text-600">{description}</p>
      </Card.Body>
    </Card>
  </Col>
);

const Home = () => {
  const [currentRotationId, setCurrentRotationId] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentRotation = async () => {
      try {
        setLoading(true);
        // Get all rotations
        const rotations = await rotationsService.getAll();
        
        // Find the current rotation (in a real API, you might have a dedicated endpoint for this)
        const currentRotation = rotations.find(rotation => 
          new Date(rotation.startDate) <= new Date() && 
          new Date(rotation.endDate) >= new Date()
        );
        
        if (currentRotation) {
          setCurrentRotationId(currentRotation.id);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching current rotation:', err);
        setLoading(false);
      }
    };

    fetchCurrentRotation();
  }, []);

  const navItems = [
    {
      to: paths.viewRotation(`view/${currentRotationId}`),
      icon: ['fas', 'calendar-check'],
      title: 'Current Rotation',
      description: 'View and manage the current rotation'
    },
    {
      to: '/dashboard/new-rotation',
      icon: ['fas', 'calendar-plus'],
      title: 'Create New Rotation',
      description: 'Create a new staff rotation'
    },
    {
      to: '/admin/personnel',
      icon: ['fas', 'users'],
      title: 'Personnel',
      description: 'View personnel skills matrix'
    },
    {
      to: '/admin/review-requests',
      icon: ['fas', 'tasks'],
      title: 'Review Requests',
      description: 'Review and manage staff requests'
    },
    {
      to: '/admin/rotations-list',
      icon: ['fas', 'list-ul'],
      title: 'Rotations List',
      description: 'View all available rotations'
    },
    {
      to: '/user/calendar',
      icon: ['fas', 'calendar-alt'],
      title: 'My Calendar',
      description: 'View your personal schedule'
    },
    {
      to: '/user/requests',
      icon: ['fas', 'clock'],
      title: 'My Requests',
      description: 'Submit a request for an assignment'
    }
  ];

  return (
    <div className="pt-5">
      <div className="mb-6">
        <h2 className="mb-2">Welcome to Personnel Rotation Optimizer</h2>
        <h5 className="text-700 fw-semi-bold">Select an option to get started</h5>
      </div>
      <Row>
        {navItems.map((item, index) => (
          <NavTile key={index} {...item} />
        ))}
      </Row>
    </div>
  );
};

export default Home; 