/**
 * Mock Services
 * 
 * This file exports mock services that mimic the API structure.
 * Each service corresponds to a feature area and provides methods
 * that match the API endpoints.
 */

import { 
  personnelData, 
  skillsList, 
  rotationsListData, 
  userRequestsData, 
  reviewRequestsData, 
  generateUserCalendarShifts,
  mockRotations
} from 'data/mock';

// Import wizard service
import { wizardService } from './wizardService';

// Personnel service
export const personnelService = {
  // Get all personnel
  getAll: async () => {
    return personnelData;
  },
  
  // Get personnel by ID
  getById: async (id) => {
    const person = personnelData.find(p => p.id === parseInt(id));
    if (!person) {
      throw new Error('Personnel not found');
    }
    return person;
  },
  
  // Get all skills
  getSkills: async () => {
    return skillsList;
  }
};

// Rotations service
export const rotationsService = {
  // Get all rotations
  getAll: async () => {
    return rotationsListData;
  },
  
  // Get rotation by ID
  getById: async (id) => {
    const rotation = mockRotations[id];
    if (!rotation) {
      throw new Error('Rotation not found');
    }
    return rotation;
  },
  
  // Create a new rotation
  create: async (data) => {
    // In a real API, this would create a new rotation
    return {
      ...data,
      id: Math.max(...rotationsListData.map(r => r.id)) + 1,
      dateCreated: new Date().toISOString().split('T')[0]
    };
  },
  
  // Update an existing rotation
  update: async (id, data) => {
    // In a real API, this would update an existing rotation
    return {
      ...data,
      id: parseInt(id)
    };
  }
};

// User requests service
export const userRequestsService = {
  // Get all user requests
  getAll: async () => {
    return userRequestsData;
  },
  
  // Create a new user request
  create: async (data) => {
    // Generate a new ID for the request
    const newId = Math.max(...userRequestsData.map(r => r.id)) + 1;
    const submissionDate = new Date().toISOString().split('T')[0];
    
    // Create the new user request
    const newUserRequest = {
      ...data,
      id: newId,
      status: 'pending',
      submissionDate: submissionDate
    };
    
    // Add to userRequestsData
    userRequestsData.push(newUserRequest);
    
    // Create a corresponding review request for admin
    const newReviewRequest = {
      id: newId,
      type: data.type,
      employee: 'Liam Tremblay', // In a real app, this would be the current user's name
      date: data.date,
      submissionDate: submissionDate,
      status: 'pending'
    };
    
    // Add to reviewRequestsData
    reviewRequestsData.push(newReviewRequest);
    
    return newUserRequest;
  },
  
  // Update an existing user request
  update: async (id, data) => {
    // Find the user request to update
    const requestIndex = userRequestsData.findIndex(r => r.id === parseInt(id));
    
    if (requestIndex !== -1) {
      // Update the user request
      userRequestsData[requestIndex] = {
        ...userRequestsData[requestIndex],
        ...data
      };
      
      // Also update the corresponding review request
      const reviewRequestIndex = reviewRequestsData.findIndex(r => r.id === parseInt(id));
      
      if (reviewRequestIndex !== -1) {
        reviewRequestsData[reviewRequestIndex] = {
          ...reviewRequestsData[reviewRequestIndex],
          ...data
        };
      }
      
      return userRequestsData[requestIndex];
    }
    
    throw new Error('Request not found');
  }
};

// Review requests service
export const reviewRequestsService = {
  // Get all review requests
  getAll: async () => {
    return reviewRequestsData;
  },
  
  // Update an existing review request
  update: async (id, data) => {
    // Find the review request to update
    const requestIndex = reviewRequestsData.findIndex(r => r.id === parseInt(id));
    
    if (requestIndex !== -1) {
      // Update the review request
      reviewRequestsData[requestIndex] = {
        ...reviewRequestsData[requestIndex],
        ...data
      };
      
      // Also update the corresponding user request
      const userRequestIndex = userRequestsData.findIndex(r => r.id === parseInt(id));
      
      if (userRequestIndex !== -1) {
        userRequestsData[userRequestIndex] = {
          ...userRequestsData[userRequestIndex],
          ...data
        };
      }
      
      return reviewRequestsData[requestIndex];
    }
    
    throw new Error('Request not found');
  }
};

// User calendar service
export const userCalendarService = {
  // Get user calendar shifts
  getShifts: async () => {
    return generateUserCalendarShifts();
  }
};

// Export wizard service
export { wizardService }; 