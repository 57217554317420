import React, { useState, useEffect } from 'react';
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubtleBadge from 'components/common/SubtleBadge';
import { reviewRequestsService } from 'services';

const ReviewRequests = () => {
  const [statusFilter, setStatusFilter] = useState('all');
  const [requests, setRequests] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setLoading(true);
        const data = await reviewRequestsService.getAll();
        setRequests(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching review requests:', err);
        setError('Failed to load review requests. Please try again later.');
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const handleStatusChange = async (id, newStatus) => {
    try {
      setUpdating(true);
      
      // Update the request status via the service
      const updatedRequest = await reviewRequestsService.update(id, { status: newStatus });
      
      // Update the local state with the updated request
      setRequests(prevRequests => 
        prevRequests.map(request => 
          request.id === id ? { ...request, status: newStatus } : request
        )
      );
      
      setUpdating(false);
    } catch (err) {
      console.error('Error updating request status:', err);
      alert('Failed to update request status. Please try again.');
      setUpdating(false);
    }
  };

  const filteredRequests = requests
    .filter(request => 
      (statusFilter === 'all' || request.status === statusFilter) &&
      (searchTerm === '' || 
        request.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.employee.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.date.toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .sort((a, b) => {
      if (a.status === 'pending' && b.status !== 'pending') return -1;
      if (a.status !== 'pending' && b.status === 'pending') return 1;
      return new Date(b.submissionDate) - new Date(a.submissionDate);
    });

  if (loading) {
    return (
      <Card>
        <Card.Body className="text-center p-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-3 text-700">Loading review requests...</p>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <Card.Body className="text-center p-5">
          <div className="text-danger mb-3">
            <FontAwesomeIcon icon="exclamation-triangle" size="2x" />
          </div>
          <p className="text-700">{error}</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs="auto">
            <h6 className="mb-0">Review Requests</h6>
          </Col>
          <Col xs={12} md="auto" className="mt-3 mt-md-0">
            <Row className="g-3 align-items-center">
              <Col xs="auto">
                <Form.Select 
                  size="sm" 
                  value={statusFilter} 
                  onChange={(e) => setStatusFilter(e.target.value)}
                  style={{ width: '150px' }}
                >
                  <option value="all">All Status</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </Form.Select>
              </Col>
              <Col xs="auto">
                <Form.Control
                  size="sm"
                  type="search"
                  placeholder="Search requests..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: '200px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Table responsive className="fs--1 mb-0">
          <thead>
            <tr className="bg-200 text-900 text-nowrap">
              <th>Request Type</th>
              <th>Employee</th>
              <th>Submitted On</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredRequests.length === 0 ? (
              <tr>
                <td colSpan="5" className="text-center py-3">
                  No requests found
                </td>
              </tr>
            ) : (
              filteredRequests.map(request => (
                <tr key={request.id} className="align-middle">
                  <td>
                    <div className="fw-semi-bold">{request.type}</div>
                    <div className="text-600">on {request.date}</div>
                  </td>
                  <td>
                    <div className="fw-semi-bold">{request.employee}</div>
                  </td>
                  <td>
                    <div className="text-600">{request.submissionDate}</div>
                  </td>
                  <td>
                    <SubtleBadge bg={
                      request.status === 'approved' 
                        ? 'success' 
                        : request.status === 'rejected'
                        ? 'danger'
                        : 'warning'
                    }>
                      {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                    </SubtleBadge>
                  </td>
                  <td>
                    <div>
                      <Button 
                        variant={request.status === 'approved' ? 'falcon-success' : 'outline-success'}
                        size="sm" 
                        className="me-2"
                        onClick={() => handleStatusChange(request.id, 'approved')}
                        disabled={request.status === 'approved' || updating}
                      >
                        {updating && request.status !== 'approved' ? (
                          <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        ) : (
                          <FontAwesomeIcon icon="check" className="me-1" />
                        )}
                        Approve
                      </Button>
                      <Button 
                        variant={request.status === 'rejected' ? 'falcon-danger' : 'outline-danger'}
                        size="sm"
                        onClick={() => handleStatusChange(request.id, 'rejected')}
                        disabled={request.status === 'rejected' || updating}
                      >
                        {updating && request.status !== 'rejected' ? (
                          <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        ) : (
                          <FontAwesomeIcon icon="times" className="me-1" />
                        )}
                        Reject
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ReviewRequests; 