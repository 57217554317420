/**
 * Consolidated Mock Data
 * 
 * This file contains all mock data used in the application.
 * It's organized by feature area to make it easier to migrate to a backend API.
 */

// Import the existing rotations mock data
import mockRotations from './rotations';

// ======================================================
// PERSONNEL DATA
// ======================================================

export const personnelData = [
  { id: 1, name: 'Liam Tremblay', type: '1.0', email: 'l.tremblay@hospital.ca', skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'MOR-83', 'SP63', 'IC1-8', 'M-8', 'Autopsy'] },
  { id: 2, name: 'Emma Roy', type: '1.0', email: 'e.roy@hospital.ca', skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'SP63', 'PA'] },
  { id: 3, name: 'Noah Bouchard', type: '1.0', email: 'n.bouchard@hospital.ca', skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'SP63', 'IC1-8', 'Renal'] },
  { id: 4, name: 'Alexander Lee', type: '0.6', email: 'a.lee@hospital.ca', skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'MOR-83', 'Autopsy', 'PA'] },
  { id: 5, name: 'Thomas Anderson', type: '1.0', email: 't.anderson@hospital.ca', skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'IC1-8', 'CYSC-8', 'M-8'] }
];

export const skillsList = [
  'ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'MOR-83', 
  'SP63', 'IC1-8', 'CYSC-8', 'Renal', 'M-8', 'Autopsy', 'PA'
];

// ======================================================
// ROTATIONS LIST DATA
// ======================================================

export const rotationsListData = [
  {
    id: 1,
    dateCreated: '2024-01-15',
    startDate: '2024-02-01',
    endDate: '2024-02-28',
    department: 'Emergency',
    team: 'Night Shift',
    admin: 'Boby Grewal'
  },
  {
    id: 2,
    dateCreated: '2024-01-16',
    startDate: '2024-02-15',
    endDate: '2024-03-15',
    department: 'Surgery',
    team: 'Day Shift',
    admin: 'Sarah Thompson'
  },
  {
    id: 3,
    dateCreated: '2024-01-17',
    startDate: '2024-03-01',
    endDate: '2024-03-31',
    department: 'Pediatrics',
    team: 'Evening Shift',
    admin: 'Jennifer Clark'
  }
];

// ======================================================
// USER REQUESTS DATA
// ======================================================

export const userRequestsData = [
  { 
    id: 1, 
    type: 'Day off', 
    date: 'January 5th', 
    status: 'approved',
    employee: 'Liam Tremblay',
    submissionDate: '2023-12-20'
  },
  { 
    id: 2, 
    type: 'Evening Shift', 
    date: 'Thursdays', 
    status: 'rejected',
    employee: 'Emma Roy',
    submissionDate: '2023-12-19'
  }
];

// ======================================================
// ADMIN REVIEW REQUESTS DATA
// ======================================================

export const reviewRequestsData = [
  {
    id: 1,
    type: 'Day off',
    employee: 'Liam Tremblay',
    date: 'January 5th, 2024',
    submissionDate: '2023-12-20',
    status: 'pending'
  },
  {
    id: 2,
    type: 'Evening Shift',
    employee: 'Emma Roy',
    date: 'Every Thursday',
    submissionDate: '2023-12-19',
    status: 'approved'
  },
  {
    id: 3,
    type: 'Night Shift',
    employee: 'Noah Bouchard',
    date: 'January 10th, 2024',
    submissionDate: '2023-12-18',
    status: 'rejected'
  }
];

// ======================================================
// USER CALENDAR DATA
// ======================================================

export const generateUserCalendarShifts = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const shifts = [];
  let id = 1;

  // Get the first Sunday of the month or the first day if it's a Sunday
  let startDate = new Date(firstDay);
  startDate.setDate(firstDay.getDate() - firstDay.getDay());

  // Get the last Saturday of the month or the last day if it's a Saturday
  let endDate = new Date(lastDay);
  if (endDate.getDay() !== 6) {
    endDate.setDate(lastDay.getDate() + (6 - lastDay.getDay()));
  }

  // Generate shifts for each day (randomly for demo)
  let currentDay = new Date(startDate);
  while (currentDay <= endDate) {
    // Randomly assign shifts (30% chance of having a shift)
    if (Math.random() < 0.3) {
      const isDay = Math.random() < 0.5;
      const shiftStart = new Date(currentDay);
      const shiftEnd = new Date(currentDay);
      
      if (isDay) {
        // Day shift: 07:00 to 15:00
        shiftStart.setHours(7, 0, 0);
        shiftEnd.setHours(15, 0, 0);
      } else {
        // Night shift: 23:00 to 07:00 next day
        shiftStart.setHours(23, 0, 0);
        shiftEnd.setDate(shiftEnd.getDate() + 1);
        shiftEnd.setHours(7, 0, 0);
      }

      const shift = {
        id: id++,
        title: isDay ? 'Day Shift' : 'Night Shift',
        start: shiftStart.toISOString(),
        end: shiftEnd.toISOString(),
        backgroundColor: isDay ? '#00d27a' : '#27bcfd',
        borderColor: 'transparent',
        role: Math.random() < 0.5 ? 'Primary' : 'Secondary',
        extendedProps: {
          date: currentDay.toLocaleDateString(),
          shiftType: isDay ? 'Day' : 'Night'
        }
      };
      shifts.push(shift);
    }
    currentDay.setDate(currentDay.getDate() + 1);
  }

  return shifts;
};

// ======================================================
// ROTATION WIZARD DATA
// ======================================================

export const wizardUnions = [
  { id: 'sph_pathology', name: 'SPH Pathology' },
  { id: 'vgh_radiology', name: 'VGH Radiology' },
  { id: 'bc_nurses', name: 'BC Nurses Union' },
  { id: 'heu', name: 'Hospital Employees Union' }
];

export const wizardRules = [
  { id: 'max_consecutive_days', name: 'Maximum Consecutive Days', description: 'No more than 5 consecutive days worked' },
  { id: 'min_rest_between_shifts', name: 'Minimum Rest Between Shifts', description: 'At least 12 hours between shifts' },
  { id: 'weekend_distribution', name: 'Weekend Distribution', description: 'Weekends off distributed equitably' },
  { id: 'holiday_compensation', name: 'Holiday Compensation', description: 'Premium pay for statutory holidays' },
  { id: 'shift_rotation', name: 'Shift Rotation', description: 'Forward rotation of shifts (day to evening to night)' },
  { id: 'overtime_rules', name: 'Overtime Rules', description: 'Time and a half after 8 hours, double time after 12 hours' }
];

export const wizardRotationTypes = [
  { id: 'line', name: 'Line Rotation' },
  { id: 'block', name: 'Block Rotation' }
];

export const wizardWeekStartOptions = [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
];

export const wizardDefaultShifts = [
  { id: 'day', name: 'Day', startTime: '07:00', endTime: '15:00', color: '#00d27a', enabled: true },
  { id: 'evening', name: 'Evening', startTime: '15:00', endTime: '23:00', color: '#f5803e', enabled: true },
  { id: 'night', name: 'Night', startTime: '23:00', endTime: '07:00', color: '#27bcfd', enabled: true },
  { id: 'off', name: 'Off', startTime: '', endTime: '', color: '#edf2f9', enabled: true }
];

export const wizardDefaultStaffing = {
  fullTime: { count: 5, minHours: 38, maxHours: 38 },
  partTime: { count: 3, minHours: 25, maxHours: 25 },
  casual: { count: 4, minHours: 15, maxHours: 15 }
};

export const wizardHolidays = [
  { id: 1, date: '2024-01-01', name: 'New Year\'s Day' },
  { id: 2, date: '2024-02-19', name: 'Family Day' },
  { id: 3, date: '2024-03-29', name: 'Good Friday' },
  { id: 4, date: '2024-05-20', name: 'Victoria Day' },
  { id: 5, date: '2024-07-01', name: 'Canada Day' },
  { id: 6, date: '2024-08-05', name: 'BC Day' },
  { id: 7, date: '2024-09-02', name: 'Labour Day' },
  { id: 8, date: '2024-10-14', name: 'Thanksgiving' },
  { id: 9, date: '2024-11-11', name: 'Remembrance Day' },
  { id: 10, date: '2024-12-25', name: 'Christmas Day' },
  { id: 11, date: '2024-12-26', name: 'Boxing Day' }
];

export const wizardRequests = [
  { id: 1, employeeId: 1, employeeName: 'Liam Tremblay', type: 'day_off', date: '2024-02-15', status: 'pending' },
  { id: 2, employeeId: 2, employeeName: 'Emma Roy', type: 'day_shift', date: '2024-02-20', status: 'approved' },
  { id: 3, employeeId: 3, employeeName: 'Noah Bouchard', type: 'night_shift', date: '2024-02-25', status: 'pending' },
  { id: 4, employeeId: 4, employeeName: 'Alexander Lee', type: 'day_off', date: '2024-03-05', status: 'rejected' }
];

// Export the existing rotations data
export { mockRotations };

// Default export for backward compatibility
export default {
  rotations: mockRotations,
  personnel: personnelData,
  skills: skillsList,
  rotationsList: rotationsListData,
  userRequests: userRequestsData,
  reviewRequests: reviewRequestsData,
  generateUserCalendarShifts,
  wizard: {
    unions: wizardUnions,
    rules: wizardRules,
    rotationTypes: wizardRotationTypes,
    weekStartOptions: wizardWeekStartOptions,
    defaultShifts: wizardDefaultShifts,
    defaultStaffing: wizardDefaultStaffing,
    holidays: wizardHolidays,
    requests: wizardRequests
  }
}; 