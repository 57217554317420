import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import CustomWizard from './CustomWizard';

const FormTitle = ({ icon, title }) => {
  return (
    <Flex className="mb-4">
      <span className="fa-stack me-2 ms-n1">
        <FontAwesomeIcon icon="circle" className="fa-stack-2x text-300" />
        <FontAwesomeIcon
          icon={icon}
          className="fa-inverse fa-stack-1x text-primary"
        />
      </span>
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-n1"></span>
        </h5>
      </div>
    </Flex>
  );
};

const NewRotation = () => {
  return (
    <>
      <Row className="my-5 g-4">
        <Col lg={12} xl={12} xxl={12} className="mt-0 h-100">
          <FormTitle icon="sync" title="New Rotation" />
          <CustomWizard validation={true} progressBar={true} />
        </Col>
      </Row>
    </>
  );
};

FormTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default NewRotation;
