/**
 * API Configuration
 * 
 * This file contains configuration for API calls and mock data.
 * Toggle the useMockData flag to switch between mock data and real API calls.
 */

// Set this to false when you want to use real API calls
export const useMockData = true;

// Base URL for API calls
export const apiBaseUrl = process.env.REACT_APP_API_URL || 'https://api.example.com';

// API endpoints
export const apiEndpoints = {
  // Personnel endpoints
  personnel: {
    getAll: '/personnel',
    getById: (id) => `/personnel/${id}`,
    getSkills: '/personnel/skills'
  },
  
  // Rotations endpoints
  rotations: {
    getAll: '/rotations',
    getById: (id) => `/rotations/${id}`,
    create: '/rotations',
    update: (id) => `/rotations/${id}`
  },
  
  // User requests endpoints
  userRequests: {
    getAll: '/user-requests',
    create: '/user-requests',
    update: (id) => `/user-requests/${id}`
  },
  
  // Admin review requests endpoints
  reviewRequests: {
    getAll: '/review-requests',
    update: (id) => `/review-requests/${id}`
  },
  
  // User calendar endpoints
  userCalendar: {
    getShifts: '/user-calendar/shifts'
  },
  
  // Wizard endpoints
  wizard: {
    getUnions: '/wizard/unions',
    getRules: '/wizard/rules',
    getRotationTypes: '/wizard/rotation-types',
    getWeekStartOptions: '/wizard/week-start-options',
    getDefaultShifts: '/wizard/default-shifts',
    getDefaultStaffing: '/wizard/default-staffing',
    getHolidays: '/wizard/holidays',
    getRequests: '/wizard/requests',
    createRotation: '/wizard/create-rotation'
  }
};

export default {
  useMockData,
  apiBaseUrl,
  apiEndpoints
}; 