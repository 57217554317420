import React, { useState, useEffect } from 'react';
import { Card, Table, Badge, Form } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { wizardService } from 'services';

const RequestsStep = ({ requests, setRequests }) => {
  const [approvedRequests, setApprovedRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch approved requests on component mount
  useEffect(() => {
    const fetchApprovedRequests = async () => {
      try {
        setLoading(true);
        const allRequests = await wizardService.getRequests();
        // Filter only approved requests and add selected property
        const approved = allRequests
          .filter(request => request.status === 'approved')
          .map(request => ({ ...request, selected: false }));
        setApprovedRequests(approved);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching approved requests:', err);
        setError('Failed to load approved requests. Please try again later.');
        setLoading(false);
      }
    };
    
    fetchApprovedRequests();
  }, []);

  // Update parent component's requests state when selections change
  useEffect(() => {
    const selectedRequests = approvedRequests.filter(request => request.selected);
    setRequests(selectedRequests);
  }, [approvedRequests, setRequests]);

  // Handle checkbox change
  const handleRequestSelection = (id) => {
    setApprovedRequests(prevRequests => 
      prevRequests.map(request => 
        request.id === id 
          ? { ...request, selected: !request.selected } 
          : request
      )
    );
  };

  // Helper function to get badge color based on request type
  const getRequestTypeColor = (type) => {
    switch (type) {
      case 'day_off':
        return 'danger';
      case 'day_shift':
        return 'success';
      case 'night_shift':
        return 'primary';
      default:
        return 'secondary';
    }
  };

  // Helper function to format request type for display
  const formatRequestType = (type) => {
    return type.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  return (
    <div className="h-100 d-flex flex-column" style={{ minHeight: 0 }}>
      <Card className="mb-0 h-100">
        <Card.Header className="bg-light border-bottom">
          <h5 className="mb-0 text-900">Approved Requests</h5>
        </Card.Header>
        <Card.Body className="p-0">
          <SimpleBar style={{ height: '100%', maxHeight: 'calc(100vh - 400px)' }}>
            {loading ? (
              <div className="text-center p-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : error ? (
              <div className="text-center p-4 text-danger">
                <span className="fas fa-exclamation-triangle me-2"></span>
                {error}
              </div>
            ) : approvedRequests.length === 0 ? (
              <div className="text-center p-4 text-muted">
                <span className="fas fa-info-circle me-2"></span>
                No approved requests found.
              </div>
            ) : (
              <Table responsive hover className="mb-0 fs--1">
                <thead className="bg-body-tertiary">
                  <tr>
                    <th className="ps-3">Select</th>
                    <th>Employee</th>
                    <th>Request Type</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {approvedRequests.map((request) => (
                    <tr key={request.id}>
                      <td className="ps-3">
                        <Form.Check
                          type="checkbox"
                          id={`request-${request.id}`}
                          checked={request.selected}
                          onChange={() => handleRequestSelection(request.id)}
                          className="mb-0"
                        />
                      </td>
                      <td>{request.employeeName}</td>
                      <td>
                        <Badge bg={getRequestTypeColor(request.type)} className="me-2">
                          {formatRequestType(request.type)}
                        </Badge>
                      </td>
                      <td>{request.date}</td>
                      <td>
                        <Badge bg="success">Approved</Badge>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </SimpleBar>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RequestsStep; 