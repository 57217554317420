import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { wizardService } from 'services';
import CustomRuleDialog from './CustomRuleDialog';

const UnionStep = ({
  selectedUnion,
  setSelectedUnion,
  selectedRules,
  setSelectedRules
}) => {
  const [showCustomRuleDialog, setShowCustomRuleDialog] = useState(false);
  const [customRules, setCustomRules] = useState([]);
  const [unions, setUnions] = useState([]);
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch unions and rules
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch unions
        const unionsData = await wizardService.getUnions();
        setUnions(unionsData);
        
        // Fetch rules
        const rulesData = await wizardService.getRules();
        setRules(rulesData);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching union data:', err);
        setError('Failed to load unions and rules. Please try again later.');
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  // Convert any numeric rules to the new format on component mount
  useEffect(() => {
    if (selectedRules.some(rule => typeof rule === 'number')) {
      const convertedRules = selectedRules.map(rule => {
        if (typeof rule === 'number' && rule <= rules.length) {
          return rules[rule - 1]?.id || '';
        }
        return rule;
      });
      setSelectedRules(convertedRules.filter(rule => typeof rule === 'string'));
    }
  }, [rules]);

  const handleRuleChange = ruleId => {
    if (typeof setSelectedRules !== 'function') {
      console.error('setSelectedRules is not properly initialized');
      return;
    }
    setSelectedRules(prev => {
      const currentRules = Array.isArray(prev) ? prev : [];
      if (currentRules.includes(ruleId)) {
        return currentRules.filter(item => item !== ruleId);
      } else {
        return [...currentRules, ruleId];
      }
    });
  };

  const handleAddRule = () => {
    setShowCustomRuleDialog(true);
  };

  const handleAddCustomRule = (ruleData) => {
    const newRule = {
      id: `custom_${customRules.length + 1}`,
      name: ruleData.name || `Custom Rule ${customRules.length + 1}`,
      description: ruleData.description,
      example: ruleData.example
    };
    setCustomRules(prev => [...prev, newRule]);
    // Automatically select the new custom rule
    handleRuleChange(newRule.id);
  };

  // Combine fetched rules with custom rules
  const allRules = [...rules, ...customRules];

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-5">
        <div className="text-danger mb-3">
          <span className="fas fa-exclamation-triangle fs-4"></span>
        </div>
        <h5>Error Loading Data</h5>
        <p>{error}</p>
        <Button variant="falcon-primary" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </div>
    );
  }

  return (
    <div className="h-100">
      <Row className="h-100">
        <Col md={3}>
          <div className="border-end h-100" style={{ overflowY: 'auto' }}>
            <h5 className="mb-3">Collective Agreement</h5>
            <Form>
              {unions.map(option => (
                <Form.Check
                  key={option.id}
                  type="radio"
                  id={option.id}
                  label={option.name}
                  checked={selectedUnion === option.id}
                  onChange={() => setSelectedUnion(option.id)}
                  className="mb-2"
                />
              ))}
            </Form>
          </div>
        </Col>
        <Col md={9}>
          <div className="h-100 d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Rules</h5>
              <Button
                variant="falcon-primary"
                size="sm"
                onClick={handleAddRule}
                className="d-flex align-items-center"
              >
                <span className="fas fa-plus fs--2 me-2"></span>
                Add New Rule
              </Button>
            </div>
            <div className="flex-grow-1" style={{ overflowY: 'auto' }}>
              {allRules.map(rule => (
                <div 
                  key={rule.id} 
                  className={`rule-item mb-3 p-3 border rounded ${
                    selectedRules.includes(rule.id) ? 'border-primary bg-light' : ''
                  }`}
                >
                  <div className="d-flex align-items-center mb-2">
                    <Form.Check
                      type="checkbox"
                      id={`rule-${rule.id}`}
                      checked={selectedRules.includes(rule.id)}
                      onChange={() => handleRuleChange(rule.id)}
                      className="me-3"
                    />
                    <h6 className="mb-0 fw-bold">{rule.name}</h6>
                  </div>
                  <div className="ms-4">
                    {rule.description ? (
                      <>
                        <p className="mb-2">
                          <span className="fw-semibold">Description:</span><br/>
                          {rule.description}
                        </p>
                        {rule.example && (
                          <p className="mb-0">
                            <span className="fw-semibold">Example:</span><br/>
                            {rule.example}
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="text-muted mb-0">
                        Default rule for {rule.name.toLowerCase()}.
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      
      <CustomRuleDialog
        show={showCustomRuleDialog}
        onHide={() => setShowCustomRuleDialog(false)}
        onAdd={handleAddCustomRule}
      />
    </div>
  );
};

UnionStep.propTypes = {
  selectedUnion: PropTypes.string,
  setSelectedUnion: PropTypes.func.isRequired,
  selectedRules: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number // Including number temporarily for migration
  ])),
  setSelectedRules: PropTypes.func.isRequired
};

UnionStep.defaultProps = {
  selectedUnion: '',
  selectedRules: []
};

export default UnionStep;
