export const navItems = [
  { label: 'Collective Agreement', active: true },
  { label: 'Format' },
  { label: 'Shifts' },
  { label: 'Staff' },
  { label: 'Holidays' },
  { label: 'Requests' },
  { label: 'Coverage' },
  { label: 'Review' }
];

export const unionOptions = [
  // { label: 'BCNU', value: 'bcnu' },
  // { label: 'HLU', value: 'hlu' },
  // { label: 'BC EHS', value: 'bc_ehs' },
  { label: 'SPH Pathology', value: 'sph_pathology' }
];

export const rotationOptions = [
  { label: 'Line Rotation', value: 'line' },
  { label: 'Block Rotation', value: 'block' },
  { label: 'Custom', value: 'custom' }
];

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const defaultShifts = [
  {
    id: 'day',
    name: 'Day Shift',
    label: 'D',
    start: '07:00',
    end: '15:00',
    enabled: true,
    minConsecutive: 2,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'evening',
    name: 'Evening Shift',
    label: 'E',
    start: '15:00',
    end: '23:00',
    enabled: true,
    minConsecutive: 2,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'night',
    name: 'Night Shift',
    label: 'N',
    start: '23:00',
    end: '07:00',
    enabled: true,
    minConsecutive: 2,
    maxConsecutive: 4,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'st6',
    name: 'ST-6',
    label: 'ST6',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'em63',
    name: 'EM-63',
    label: 'EM63',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'st7',
    name: 'ST-7',
    label: 'ST7',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'qc8',
    name: 'QC-8',
    label: 'QC8',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'acc8',
    name: 'ACC-8',
    label: 'ACC8',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'gr3',
    name: 'GR 3',
    label: 'GR3',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'gr1',
    name: 'GR 1',
    label: 'GR1',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'gr2',
    name: 'GR-2',
    label: 'GR2',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'mor83',
    name: 'MOR-83',
    label: 'MOR83',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'sp63',
    name: 'SP63',
    label: 'SP63',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'ic18',
    name: 'IC1-8',
    label: 'IC18',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'cysc8',
    name: 'CYSC-8',
    label: 'CYSC8',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'renal',
    name: 'Renal',
    label: 'RNL',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'm8',
    name: 'M-8',
    label: 'M8',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'autopsy',
    name: 'Autopsy',
    label: 'AUT',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'pa',
    name: 'PA',
    label: 'PA',
    start: '08:00',
    end: '16:00',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'off',
    name: 'Off Day',
    label: 'O',
    start: '',
    end: '',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 3,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  }
];

export const defaultRules = [
  {
    id: 'consecutive_shifts',
    name: 'Consecutive Shifts',
  },
  {
    id: 'consecutive_days_off',
    name: 'Consecutive Days Off',
  },
  {
    id: 'weekend_distribution',
    name: 'Weekend Distribution',
  },
  {
    id: 'shift_rotation',
    name: 'Shift Rotation',
  },
  {
    id: 'shift_distribution',
    name: 'Shift Distribution',
  }
];
