import {
  wizardUnions,
  wizardRules,
  wizardRotationTypes,
  wizardWeekStartOptions,
  wizardDefaultShifts,
  wizardDefaultStaffing,
  wizardHolidays,
  wizardRequests
} from 'data/mock';

// Mock wizard service
export const wizardService = {
  // Get all unions
  getUnions: async () => {
    return wizardUnions;
  },
  
  // Get all rules
  getRules: async () => {
    return wizardRules;
  },
  
  // Get all rotation types
  getRotationTypes: async () => {
    return wizardRotationTypes;
  },
  
  // Get week start options
  getWeekStartOptions: async () => {
    return wizardWeekStartOptions;
  },
  
  // Get default shifts
  getDefaultShifts: async () => {
    return wizardDefaultShifts;
  },
  
  // Get default staffing
  getDefaultStaffing: async () => {
    return wizardDefaultStaffing;
  },
  
  // Get holidays
  getHolidays: async () => {
    return wizardHolidays;
  },
  
  // Get requests
  getRequests: async () => {
    return wizardRequests;
  },
  
  // Create a new rotation from wizard data
  createRotation: async (wizardData) => {
    // In a real API, this would create a new rotation
    console.log('Creating rotation with wizard data:', wizardData);
    
    // Simulate API response with a new rotation ID
    return {
      id: Math.floor(Math.random() * 1000) + 10,
      ...wizardData,
      dateCreated: new Date().toISOString().split('T')[0],
      status: 'processing'
    };
  }
};

export default wizardService; 