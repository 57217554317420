import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { personnelService } from 'services';

const PersonnelProfileModal = ({ show, onHide, person }) => {
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSkills = async () => {
      try {
        if (show) {
          setLoading(true);
          const skillsData = await personnelService.getSkills();
          setSkills(skillsData);
          setLoading(false);
        }
      } catch (err) {
        console.error('Error fetching skills:', err);
        setLoading(false);
      }
    };

    fetchSkills();
  }, [show]);

  if (!person) return null;

  // Create rows of 8 skills each for better display
  const rows = [];
  for (let i = 0; i < skills.length; i += 8) {
    rows.push(skills.slice(i, i + 8));
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Personnel Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} className="mb-4">
            <div className="d-flex align-items-center">
              <div className="avatar avatar-4xl">
                <div className="avatar-name rounded-circle bg-soft-primary text-dark fs-2">
                  {person.name.split(' ').map(n => n[0]).join('')}
                </div>
              </div>
              <div className="ms-3">
                <h3 className="mb-0">{person.name}</h3>
                <p className="text-800 fs-0 mb-0">
                  Employment Status: 
                  <span className="fw-semi-bold ms-2">
                    {person.type === '1.0' ? 'Full Time' : 'Part Time'}
                  </span>
                </p>
              </div>
            </div>
          </Col>

          <Col xs={12}>
            <h5 className="mb-3">Skills & Qualifications</h5>
            {loading ? (
              <div className="text-center py-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-2">Loading skills...</p>
              </div>
            ) : (
              <div className="table-responsive">
                <Table bordered size="sm" className="fs--1 mb-0">
                  <tbody>
                    {rows.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.map((skill) => (
                          <td key={skill} className="text-center align-middle">
                            <div className="mb-1 fw-semi-bold">{skill}</div>
                            {person.skills.includes(skill) ? (
                              <FontAwesomeIcon 
                                icon="check" 
                                className="text-success"
                              />
                            ) : (
                              <span className="text-300">—</span>
                            )}
                          </td>
                        ))}
                        {/* Fill empty cells if row is not complete */}
                        {Array(8 - row.length).fill().map((_, i) => (
                          <td key={`empty-${i}`}></td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Col>

          <Col xs={12} className="mt-4">
            <h5 className="mb-3">Additional Information</h5>
            <Row className="g-3">
              <Col sm={6}>
                <div className="border rounded-3 p-3 h-100">
                  <h6 className="text-800">Total Skills</h6>
                  <h3 className="fw-semi-bold mb-0">{person.skills.length}</h3>
                </div>
              </Col>
              <Col sm={6}>
                <div className="border rounded-3 p-3 h-100">
                  <h6 className="text-800">Employment Type</h6>
                  <h3 className="fw-semi-bold mb-0">
                    {person.type === '1.0' ? 'Full Time' : 'Part Time'}
                  </h3>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PersonnelProfileModal; 