import React, { useState, useEffect } from 'react';
import { 
  Card,
  Form,
  Row,
  Col,
  Button,
  Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import SubtleBadge from 'components/common/SubtleBadge';
import 'react-datepicker/dist/react-datepicker.css';
import { userRequestsService } from 'services';

const UserRequests = () => {
  const [requestType, setRequestType] = useState('shift');
  const [shiftType, setShiftType] = useState('off');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setLoading(true);
        const data = await userRequestsService.getAll();
        setRequests(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching requests:', err);
        setError('Failed to load requests. Please try again later.');
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!startDate) {
      alert('Please select a date');
      return;
    }
    
    try {
      setSubmitting(true);
      
      const newRequest = {
        type: requestType === 'shift' ? `${shiftType} Shift` : 'Day off',
        date: startDate && endDate 
          ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
          : startDate?.toLocaleDateString() || 'No date selected'
      };

      // Submit the request to the service
      const createdRequest = await userRequestsService.create(newRequest);
      
      // Update the local state with the new request
      setRequests(prevRequests => [...prevRequests, createdRequest]);
      
      // Reset form
      setDateRange([null, null]);
      setSubmitting(false);
    } catch (err) {
      console.error('Error submitting request:', err);
      alert('Failed to submit request. Please try again.');
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Card>
        <Card.Body className="text-center p-5">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <p className="mt-3 text-700">Loading requests...</p>
        </Card.Body>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <Card.Body className="text-center p-5">
          <div className="text-danger mb-3">
            <FontAwesomeIcon icon="exclamation-triangle" size="2x" />
          </div>
          <p className="text-700">{error}</p>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="border-bottom border-200">
          <h6 className="mb-0">Previous Requests</h6>
        </Card.Header>
        <Card.Body className="p-0">
          <Table className="fs--1 mb-0">
            <thead>
              <tr>
                <th className="bg-200 text-900 text-nowrap align-middle">Request Type</th>
                <th className="bg-200 text-900 text-nowrap align-middle">Status</th>
              </tr>
            </thead>
            <tbody>
              {requests.length === 0 ? (
                <tr>
                  <td colSpan="2" className="text-center py-3">
                    No requests found
                  </td>
                </tr>
              ) : (
                requests.map(request => (
                  <tr key={request.id} className="align-middle white-space-nowrap">
                    <td>
                      <div className="fw-semi-bold">{request.type}</div>
                      <div className="text-600">on {request.date}</div>
                    </td>
                    <td>
                      <SubtleBadge bg={
                        request.status === 'approved' 
                          ? 'success' 
                          : request.status === 'rejected'
                          ? 'danger'
                          : 'warning'
                      }>
                        {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                      </SubtleBadge>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <h5 className="mb-3">New Request</h5>
          <Form onSubmit={handleSubmit}>
            <div className="mb-3">
              <div className="text-700 mb-2">Request Type</div>
              <Form.Check
                type="radio"
                inline
                label="Shift Assignment"
                name="requestType"
                id="shift"
                checked={requestType === 'shift'}
                onChange={() => setRequestType('shift')}
                className="me-3"
              />
              <Form.Check
                type="radio"
                inline
                label="Day(s) Off"
                name="requestType"
                id="off"
                checked={requestType === 'off'}
                onChange={() => setRequestType('off')}
              />
            </div>

            {requestType === 'shift' && (
              <div className="mb-3">
                <div className="text-700 mb-2">Select the shift type:</div>
                <div>
                  <Form.Check
                    type="radio"
                    inline
                    label="Day (D)"
                    name="shiftType"
                    id="day"
                    checked={shiftType === 'day'}
                    onChange={() => setShiftType('day')}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    inline
                    label="Night (N)"
                    name="shiftType"
                    id="night"
                    checked={shiftType === 'night'}
                    onChange={() => setShiftType('night')}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    inline
                    label="Evening"
                    name="shiftType"
                    id="evening"
                    checked={shiftType === 'evening'}
                    onChange={() => setShiftType('evening')}
                    className="me-3"
                  />
                  <Form.Check
                    type="radio"
                    inline
                    label="Off"
                    name="shiftType"
                    id="off"
                    checked={shiftType === 'off'}
                    onChange={() => setShiftType('off')}
                  />
                </div>
              </div>
            )}

            <div className="mb-3">
              <div className="text-700 mb-2">Select Date Range</div>
              <Row>
                <Col md={4} lg={3}>
                  <Form.Control
                    type="text"
                    value={startDate && endDate ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}` : ''}
                    placeholder="Please select a date range"
                    readOnly
                    className="mb-2"
                    style={{ width: '250px' }}
                  />
                </Col>
              </Row>
              <DatePicker
                selected={startDate}
                onChange={dates => setDateRange(dates)}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                calendarClassName="border-0"
              />
            </div>

            <div className="text-end">
              <Button 
                variant="primary" 
                type="submit"
                disabled={submitting || !startDate}
              >
                {submitting ? (
                  <>
                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    Submitting...
                  </>
                ) : (
                  <>
                    Submit Request
                    <FontAwesomeIcon icon="paper-plane" className="ms-2" />
                  </>
                )}
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </>
  );
};

export default UserRequests; 