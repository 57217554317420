import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { wizardService } from 'services';
import lineRotationImage from '../../../../assets/img/app/line-rotation-format.png';
import blockRotationImage from '../../../../assets/img/app/block-rotation-format.png';

const FormatStep = ({ 
  selectedRotation, 
  setSelectedRotation,
  weekStartsOn,
  setWeekStartsOn,
  numberOfDays,
  setNumberOfDays 
}) => {
  const [rotationTypes, setRotationTypes] = useState([]);
  const [weekStartOptions, setWeekStartOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch rotation types and week start options
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch rotation types
        const rotationTypesData = await wizardService.getRotationTypes();
        setRotationTypes(rotationTypesData);
        
        // If no rotation is selected yet, select the first one
        if (!selectedRotation && rotationTypesData.length > 0) {
          setSelectedRotation(rotationTypesData[0].id);
        }
        
        // Fetch week start options
        const weekStartOptionsData = await wizardService.getWeekStartOptions();
        setWeekStartOptions(weekStartOptionsData);
        
        // If no week start option is selected yet, select the first one
        if (!weekStartsOn && weekStartOptionsData.length > 0) {
          setWeekStartsOn(weekStartOptionsData[0]);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching format data:', err);
        setError('Failed to load rotation types and week options. Please try again later.');
        setLoading(false);
      }
    };
    
    fetchData();
  }, [selectedRotation, setSelectedRotation, weekStartsOn, setWeekStartsOn]);

  const getRotationImage = () => {
    if (selectedRotation === 'block') {
      const blockStyle = {
        width: '400px',
        height: 'auto',
        transform: 'scale(0.8)'
      };
      
      return <img 
        src={blockRotationImage} 
        alt="Block rotation format"
        style={blockStyle}
        className="rotation-format-image"
      />;
    } else if (selectedRotation === 'line') {
      const lineStyle = {
        width: 'auto',
        height: '200px',
        transform: 'scale(0.8)'
      };
      
      return <img 
        src={lineRotationImage} 
        alt="Line rotation format"
        style={lineStyle}
        className="rotation-format-image"
      />;
    }
    return null;
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-5">
        <div className="text-danger mb-3">
          <span className="fas fa-exclamation-triangle fs-4"></span>
        </div>
        <h5>Error Loading Data</h5>
        <p>{error}</p>
        <Button variant="falcon-primary" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </div>
    );
  }

  return (
    <SimpleBar style={{ height: '100%' }}>
      <div className="h-100 p-3">
        <Row>
          <Col md={4}>
            <Card className="mb-3">
              <Card.Header className="bg-light">
                <h6 className="mb-0">Rotation Format</h6>
              </Card.Header>
              <Card.Body>
                {rotationTypes.map(option => (
                  <Form.Check
                    key={option.id}
                    type="radio"
                    id={option.id}
                    label={option.name}
                    checked={selectedRotation === option.id}
                    onChange={() => setSelectedRotation(option.id)}
                    className="mb-2"
                  />
                ))}
              </Card.Body>
            </Card>

            <Card>
              <Card.Header className="bg-light">
                <h6 className="mb-0">Schedule Settings</h6>
              </Card.Header>
              <Card.Body>
                <Form.Group className="mb-3">
                  <Form.Label>Week starts on:</Form.Label>
                  <Form.Select
                    value={weekStartsOn}
                    onChange={e => setWeekStartsOn(e.target.value)}
                  >
                    {weekStartOptions.map(day => (
                      <option key={day} value={day}>
                        {day}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Number of Days:</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      type="number"
                      value={numberOfDays}
                      onChange={e => setNumberOfDays(parseInt(e.target.value))}
                      min={1}
                      className="form-control-sm input-spin-none"
                      style={{ width: '100px' }}
                    />
                    <div className="falcon-button-group ms-2">
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="px-2 border-300"
                        onClick={() => setNumberOfDays(prev => Math.max(1, prev - 1))}
                      >
                        -
                      </Button>
                      <Button
                        variant="falcon-default"
                        size="sm"
                        className="px-2 border-300"
                        onClick={() => setNumberOfDays(prev => prev + 1)}
                      >
                        +
                      </Button>
                    </div>
                  </div>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          <Col md={8}>
            <Card>
              <Card.Header className="bg-light">
                <h6 className="mb-0">Explanations and examples</h6>
              </Card.Header>
              <Card.Body>
                {getRotationImage() ? (
                  <div className="rotation-image-container">
                    <div className="rotation-diagram">
                      {getRotationImage()}
                    </div>
                  </div>
                ) : (
                  <div className="text-center">
                    <p className="text-500 fs--1 mb-0">
                      Select a rotation format to view the pattern
                    </p>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </SimpleBar>
  );
};

FormatStep.propTypes = {
  selectedRotation: PropTypes.string.isRequired,
  setSelectedRotation: PropTypes.func.isRequired,
  weekStartsOn: PropTypes.string.isRequired,
  setWeekStartsOn: PropTypes.func.isRequired,
  numberOfDays: PropTypes.number.isRequired,
  setNumberOfDays: PropTypes.func.isRequired
};

export default FormatStep;
