import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

// Shared constants for table dimensions
const TABLE_DIMENSIONS = {
  CELL_WIDTH: 40,
  LABEL_WIDTH: 100, // Increased for better visibility
  CELL_HEIGHT: 25  // Further reduced from 30 to 25 for more compact height
};

const ViewRotation = ({ rotation }) => {
  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const { CELL_WIDTH, LABEL_WIDTH, CELL_HEIGHT } = TABLE_DIMENSIONS;

  const cellStyle = {
    width: `${CELL_WIDTH}px`,
    minWidth: `${CELL_WIDTH}px`,
    maxWidth: `${CELL_WIDTH}px`,
    padding: '0 !important',
    boxSizing: 'border-box',
    overflow: 'hidden',
    height: `${CELL_HEIGHT}px`,
    lineHeight: `${CELL_HEIGHT}px`,
    textAlign: 'center'
  };

  const labelColumnStyle = {
    width: `${LABEL_WIDTH}px`,
    minWidth: `${LABEL_WIDTH}px`,
    maxWidth: `${LABEL_WIDTH}px`,
    zIndex: 2,
    left: 0,
    backgroundColor: 'var(--falcon-white)',
    position: 'sticky',
    textAlign: 'center',
    height: `${CELL_HEIGHT}px`,
    padding: '0 !important',
    boxSizing: 'border-box',
    overflow: 'hidden',
    lineHeight: `${CELL_HEIGHT}px`
  };

  const totalDays = rotation.days.length;

  const renderDayNumbers = (weekIndex) => {
    return daysOfWeek.map((_, dayIndex) => {
      const dayNumber = weekIndex * 7 + dayIndex + 1;
      if (dayNumber > totalDays) return null;
      const isWeekend = dayIndex === 5 || dayIndex === 6;
      return (
        <th 
          key={`number-${weekIndex}-${dayIndex}`}
          className="text-center"
          style={{ 
            ...cellStyle,
            backgroundColor: isWeekend ? '#e3e6ed' : undefined
          }}
        >
          <div style={{ fontSize: '0.75rem' }} className="text-600">{dayNumber}</div>
        </th>
      );
    }).filter(Boolean);
  };

  const renderWeekHeader = (weekIndex) => {
    return daysOfWeek.map((day, dayIndex) => {
      const dayNumber = weekIndex * 7 + dayIndex + 1;
      if (dayNumber > totalDays) return null;
      const isWeekend = dayIndex === 5 || dayIndex === 6;
      return (
        <th 
          key={`day-${weekIndex}-${dayIndex}`}
          className="text-center fs--2"
          style={{ 
            ...cellStyle,
            backgroundColor: isWeekend ? '#e3e6ed' : undefined
          }}
        >
          {day}
        </th>
      );
    }).filter(Boolean);
  };

  const renderShiftCell = (dayIndex, staffIndex) => {
    const day = rotation.days[dayIndex];
    if (!day) return null;

    const isWeekend = dayIndex % 7 >= 5;
    const shift = day.shifts?.[staffIndex] || { label: '-' };
    const shiftLabel = shift.label || '-';
    
    return (
      <td 
        key={dayIndex} 
        style={{
          ...cellStyle,
          backgroundColor: isWeekend ? '#e3e6ed' : undefined
        }}
        className="fs--2"
      >
        {shiftLabel}
      </td>
    );
  };

  return (
    <SimpleBar>
      <div style={{ 
        width: `${LABEL_WIDTH + (totalDays * CELL_WIDTH)}px`,
        minWidth: `${LABEL_WIDTH + (totalDays * CELL_WIDTH)}px`,
        overflowX: 'auto'
      }}>
        <Table 
          bordered 
          hover 
          size="sm" 
          className="mb-0 fs--2 border-200" 
          style={{ 
            tableLayout: 'fixed',
            width: `${LABEL_WIDTH + (totalDays * CELL_WIDTH)}px`,
            borderCollapse: 'separate',
            borderSpacing: 0
          }}
        >
          <colgroup>
            <col style={{ width: `${LABEL_WIDTH}px` }} />
            {Array.from({ length: totalDays }, (_, index) => (
              <col key={index} style={{ width: `${CELL_WIDTH}px` }} />
            ))}
          </colgroup>
          
          <thead>
            <tr>
              <th style={labelColumnStyle}>&nbsp;</th>
              {[...Array(Math.ceil(totalDays / 7))].map((_, weekIndex) => (
                <React.Fragment key={`numbers-${weekIndex}`}>
                  {renderDayNumbers(weekIndex)}
                </React.Fragment>
              ))}
            </tr>
            <tr>
              <th style={labelColumnStyle}>&nbsp;</th>
              {[...Array(Math.ceil(totalDays / 7))].map((_, weekIndex) => (
                <React.Fragment key={`days-${weekIndex}`}>
                  {renderWeekHeader(weekIndex)}
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody>
            {rotation.staff.map((staff, staffIndex) => (
              <tr key={staffIndex}>
                <td style={labelColumnStyle} className="text-900 fs--2">
                  {staff.number}
                </td>
                {Array.from({ length: totalDays }, (_, dayIndex) => 
                  renderShiftCell(dayIndex, staffIndex)
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </SimpleBar>
  );
};

ViewRotation.propTypes = {
  rotation: PropTypes.shape({
    staff: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.number.isRequired
    })).isRequired,
    days: PropTypes.arrayOf(PropTypes.shape({
      shifts: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string
      }))
    })).isRequired
  }).isRequired
};

export default ViewRotation; 