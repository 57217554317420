import React, { useState, useEffect } from 'react';
import { Card, Nav, Button, Modal, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import { navItems, defaultShifts } from './wizard-steps/WizardCommon';
import UnionStep from './wizard-steps/UnionStep';
import FormatStep from './wizard-steps/FormatStep';
import ShiftsStep from './wizard-steps/ShiftsStep';
import StaffStep from './wizard-steps/StaffStep';
import HolidaysStep from './wizard-steps/HolidaysStep';
import RequestsStep from './wizard-steps/RequestsStep';
import CoverageStep from './wizard-steps/CoverageStep';
import { wizardService, rotationsService } from 'services';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';

const ReviewStep = ({ 
  selectedUnion, 
  selectedRotation, 
  weekStartsOn, 
  numberOfDays, 
  shifts, 
  staffing, 
  selectedRules,
  holidays,
  requests,
  numberOfWeeks,
  coverageMatrix,
  setActiveTab 
}) => {
  const [rotationTypeName, setRotationTypeName] = useState('');
  
  // Get rotation type name
  useEffect(() => {
    const getRotationTypeName = async () => {
      try {
        const rotationTypes = await wizardService.getRotationTypes();
        const rotationType = rotationTypes.find(type => type.id === selectedRotation);
        setRotationTypeName(rotationType ? rotationType.name : selectedRotation);
      } catch (err) {
        console.error('Error fetching rotation types:', err);
        setRotationTypeName(selectedRotation);
      }
    };
    
    getRotationTypeName();
  }, [selectedRotation]);

  const renderSection = (title, content, targetTab) => (
    <div className="review-section mb-4">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className="mb-0">{title}</h5>
        <Button 
          variant="falcon-default" 
          size="sm"
          onClick={() => setActiveTab(targetTab)}
        >
          <span className="fas fa-edit"></span> Edit
        </Button>
      </div>
      <Card className="bg-body-tertiary">
        <Card.Body>{content}</Card.Body>
      </Card>
    </div>
  );

  return (
    <div className="review-step">
      {renderSection(
        'Collective Agreement',
        <div>
          <p className="mb-1"><strong>Selected Union:</strong> {selectedUnion}</p>
          <p className="mb-0"><strong>Selected Rules:</strong> {selectedRules.join(', ') || 'None'}</p>
        </div>,
        'Collective Agreement'
      )}

      {renderSection(
        'Format',
        <p className="mb-0"><strong>Rotation Type:</strong> {rotationTypeName}</p>,
        'Format'
      )}

      {renderSection(
        'Shifts',
        <div>
          {shifts.map((shift, index) => (
            <p key={index} className="mb-1">
              <strong>{shift.name}:</strong> {shift.startTime} - {shift.endTime}
            </p>
          ))}
        </div>,
        'Shifts'
      )}

      {renderSection(
        'Staff',
        <div>
          <p className="mb-1"><strong>Full Time:</strong> {staffing.fullTime.count} staff ({staffing.fullTime.minHours}-{staffing.fullTime.maxHours} hours)</p>
          <p className="mb-1"><strong>Part Time:</strong> {staffing.partTime.count} staff ({staffing.partTime.minHours}-{staffing.partTime.maxHours} hours)</p>
          <p className="mb-0"><strong>Casual:</strong> {staffing.casual.count} staff ({staffing.casual.minHours}-{staffing.casual.maxHours} hours)</p>
        </div>,
        'Staff'
      )}

      {renderSection(
        'Holidays',
        <p className="mb-0"><strong>Number of Holidays:</strong> {holidays.length}</p>,
        'Holidays'
      )}

      {renderSection(
        'Requests',
        <p className="mb-0"><strong>Number of Requests:</strong> {requests.length}</p>,
        'Requests'
      )}

      {renderSection(
        'Coverage',
        <div>
          <p className="mb-1"><strong>Number of Weeks:</strong> {numberOfWeeks}</p>
          <p className="mb-0"><strong>Coverage Matrix:</strong> {Object.keys(coverageMatrix).length} entries</p>
        </div>,
        'Coverage'
      )}
    </div>
  );
};

const CustomWizard = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Collective Agreement');
  const [selectedUnion, setSelectedUnion] = useState('');
  const [selectedRotation, setSelectedRotation] = useState('');
  const [weekStartsOn, setWeekStartsOn] = useState('');
  const [numberOfDays, setNumberOfDays] = useState(49);
  const [shifts, setShifts] = useState([]);
  const [staffing, setStaffing] = useState({
    fullTime: { count: 5, minHours: 38, maxHours: 38 },
    partTime: { count: 3, minHours: 25, maxHours: 25 },
    casual: { count: 4, minHours: 15, maxHours: 15 }
  });
  const [selectedRules, setSelectedRules] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [requests, setRequests] = useState([]);
  const [numberOfWeeks, setNumberOfWeeks] = useState(2);
  const [coverageMatrix, setCoverageMatrix] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [createdRotation, setCreatedRotation] = useState(null);

  // Fetch initial data
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setLoading(true);
        
        // Fetch unions
        const unions = await wizardService.getUnions();
        if (unions.length > 0) {
          setSelectedUnion(unions[0].value);
        }
        
        // Fetch rotation types
        const rotationTypes = await wizardService.getRotationTypes();
        if (rotationTypes.length > 0) {
          setSelectedRotation(rotationTypes[0].id);
        }
        
        // Fetch week start options
        const weekStartOptions = await wizardService.getWeekStartOptions();
        if (weekStartOptions.length > 0) {
          setWeekStartsOn(weekStartOptions[0]);
        }
        
        // Fetch default shifts
        const defaultShifts = await wizardService.getDefaultShifts();
        setShifts(defaultShifts);
        
        // Fetch default staffing
        const defaultStaffing = await wizardService.getDefaultStaffing();
        setStaffing(defaultStaffing);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching wizard data:', err);
        setError('Failed to load wizard data. Please try again later.');
        setLoading(false);
      }
    };
    
    fetchInitialData();
  }, []);

  const handleNext = () => {
    const currentIndex = navItems.findIndex(item => item.label === activeTab);
    if (currentIndex < navItems.length - 1) {
      setActiveTab(navItems[currentIndex + 1].label);
    }
  };

  const handleBack = () => {
    const currentIndex = navItems.findIndex(item => item.label === activeTab);
    if (currentIndex > 0) {
      setActiveTab(navItems[currentIndex - 1].label);
    }
  };

  const isFirstStep = activeTab === navItems[0].label;
  const isLastStep = activeTab === navItems[navItems.length - 1].label;

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      
      // Prepare wizard data
      const wizardData = {
        union: selectedUnion,
        rotationType: selectedRotation,
        weekStartsOn,
        numberOfDays,
        shifts,
        staffing,
        rules: selectedRules,
        holidays,
        requests,
        numberOfWeeks,
        coverageMatrix
      };
      
      // Submit to service
      const result = await wizardService.createRotation(wizardData);
      setCreatedRotation(result);
      
      setSubmitting(false);
      setModalShow(true);
    } catch (err) {
      console.error('Error submitting rotation:', err);
      setError('Failed to create rotation. Please try again later.');
      setSubmitting(false);
    }
  };

  // Add a reset function to reset all form data
  const resetFormData = async () => {
    setActiveTab('Collective Agreement');
    setLoading(true);
    
    try {
      // Fetch unions
      const unions = await wizardService.getUnions();
      if (unions.length > 0) {
        setSelectedUnion(unions[0].value);
      } else {
        setSelectedUnion('');
      }
      
      // Fetch rotation types
      const rotationTypes = await wizardService.getRotationTypes();
      if (rotationTypes.length > 0) {
        setSelectedRotation(rotationTypes[0].id);
      } else {
        setSelectedRotation('');
      }
      
      // Fetch week start options
      const weekStartOptions = await wizardService.getWeekStartOptions();
      if (weekStartOptions.length > 0) {
        setWeekStartsOn(weekStartOptions[0]);
      } else {
        setWeekStartsOn('');
      }
      
      // Fetch default shifts
      const defaultShifts = await wizardService.getDefaultShifts();
      setShifts(defaultShifts);
      
      // Fetch default staffing
      const defaultStaffing = await wizardService.getDefaultStaffing();
      setStaffing(defaultStaffing);
      
      // Reset other state values
      setNumberOfDays(49);
      setSelectedRules([]);
      setHolidays([]);
      setRequests([]);
      setNumberOfWeeks(2);
      setCoverageMatrix({});
      setCreatedRotation(null);
      setError(null);
      
    } catch (err) {
      console.error('Error resetting form data:', err);
      setError('Failed to reset form data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const renderStepContent = () => {
    switch (activeTab) {
      case 'Collective Agreement':
        return (
          <UnionStep
            selectedUnion={selectedUnion}
            setSelectedUnion={setSelectedUnion}
            selectedRules={selectedRules}
            setSelectedRules={setSelectedRules}
          />
        );
      case 'Format':
        return (
          <FormatStep
            selectedRotation={selectedRotation}
            setSelectedRotation={setSelectedRotation}
            weekStartsOn={weekStartsOn}
            setWeekStartsOn={setWeekStartsOn}
            numberOfDays={numberOfDays}
            setNumberOfDays={setNumberOfDays}
          />
        );
      case 'Shifts':
        return <ShiftsStep shifts={shifts} setShifts={setShifts} />;
      case 'Staff':
        return <StaffStep staffing={staffing} setStaffing={setStaffing} />;
      case 'Holidays':
        return <HolidaysStep holidays={holidays} setHolidays={setHolidays} />;
      case 'Requests':
        return <RequestsStep requests={requests} setRequests={setRequests} />;
      case 'Coverage':
        return (
          <CoverageStep
            numberOfWeeks={numberOfWeeks}
            setNumberOfWeeks={setNumberOfWeeks}
            coverageMatrix={coverageMatrix}
            setCoverageMatrix={setCoverageMatrix}
            shifts={shifts.filter(shift => shift.enabled && shift.id !== 'off')}
          />
        );
      case 'Review':
        return (
          <ReviewStep
            selectedUnion={selectedUnion}
            selectedRotation={selectedRotation}
            weekStartsOn={weekStartsOn}
            numberOfDays={numberOfDays}
            shifts={shifts}
            staffing={staffing}
            selectedRules={selectedRules}
            holidays={holidays}
            requests={requests}
            numberOfWeeks={numberOfWeeks}
            coverageMatrix={coverageMatrix}
            setActiveTab={setActiveTab}
          />
        );
      default:
        return <div>Step content not implemented yet</div>;
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: 'calc(100vh - 200px)' }}>
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center p-5">
        <div className="text-danger mb-3">
          <span className="fas fa-exclamation-triangle fs-4"></span>
        </div>
        <h5>Error Loading Wizard</h5>
        <p>{error}</p>
        <Button variant="falcon-primary" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </div>
    );
  }

  return (
    <>
      <Card className="theme-wizard">
        <Card.Header className="bg-body-tertiary pb-2">
          <Nav className="nav-tabs">
            {navItems.map(item => (
              <Nav.Item key={item.label}>
                <Nav.Link
                  className={classNames({ active: item.label === activeTab })}
                  onClick={() => setActiveTab(item.label)}
                >
                  {item.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body className="fw-normal px-md-6 py-4" style={{ height: 'calc(100vh - 320px)', overflow: 'auto' }}>
          {renderStepContent()}
        </Card.Body>
        <Card.Footer className="px-md-6 bg-body-tertiary d-flex">
          {!isFirstStep && (
            <Button variant="link" onClick={handleBack} disabled={submitting}>
              Back
            </Button>
          )}
          <Button 
            variant="primary" 
            onClick={isLastStep ? handleSubmit : handleNext} 
            className="ms-auto"
            disabled={submitting}
          >
            {submitting ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                Processing...
              </>
            ) : isLastStep ? 'Submit' : 'Next'}
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Rotation Created</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {createdRotation && (
            <div className="mb-3">
              <p className="mb-2">Your new rotation has been created with the following details:</p>
              <div className="bg-body-tertiary p-3 rounded">
                <p className="mb-1"><strong>Rotation ID:</strong> {createdRotation.id}</p>
                <p className="mb-1"><strong>Union:</strong> {createdRotation.union}</p>
                <p className="mb-1"><strong>Type:</strong> {createdRotation.rotationType}</p>
                <p className="mb-0"><strong>Status:</strong> {createdRotation.status}</p>
              </div>
            </div>
          )}
          <p>
            Once the candidate rotations are generated you will be notified by email and will be able to view and modify the rotation under the Available Rotations.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={async () => {
            setModalShow(false);
            await resetFormData();
          }}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomWizard;
